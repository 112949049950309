import { APIConfiguration, axiosMYLAB } from 'api';
import { CreatePatientRequest, Patient, PatientResponseMPI, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { Paginate } from 'models/utils.model';
import { removeEmptyString } from 'utils';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { BaseService } from './base.service';

class PatientsService extends BaseService {
  async createPatient(body: CreatePatientRequest) {
    const { data } = await axiosMYLAB.post<Patient>(`${this.apiConfig.PATIENTS.CREATE(this.getLang())}`, body);

    this.log(`createPatient()`);

    return data;
  }
  async getPatients(parameters: PatientsListRequest, size: number = DEFAULT_PAGINATION_SIZE, page: number = 1) {
    const { data } = await axiosMYLAB.get<PatientsListResponse>(`${this.apiConfig.PATIENTS.SEARCH(this.getLang())}`, {
      params: { size, page, ...removeEmptyString(parameters) },
    });

    this.log(`getPatients()`);

    return data;
  }

  async disableRelativeFromPatient(invitationId: number) {
    const { data } = await axiosMYLAB.delete(`${this.apiConfig.PATIENTS.DELETE_RELATIVE_FROM_PATIENT(this.getLang(), invitationId)}`);

    this.log(`disableRelativeFromPatient()`);

    return data;
  }

  async getPatientsMPI(parameters: PatientsListRequest) {
    const params = {
      matricule: parameters.cns,
      first_name: parameters.first_name,
      last_name: parameters.last_name,
      birth_date: parameters.birth_date,
    };

    const { data } = await axiosMYLAB.get<PatientResponseMPI[]>(`${this.apiConfig.PATIENTS.SEARCH_MPI(this.getLang())}`, {
      params: { ...removeEmptyString(params) },
    });

    const formatedData = data.map(({ address, birth_date, birth_name, first_name, gender, matricule }) => {
      return {
        addresses: { home: address },
        cns: matricule,
        birth_date,
        last_name: birth_name,
        first_name,
        gender,
      };
    });

    this.log('getPatientsMPI()');

    return formatedData;
  }
  async getPatientDetails(patientId: number) {
    const { data } = await axiosMYLAB.get<Patient>(`${this.apiConfig.PATIENTS.GET_BY_ID(this.getLang(), patientId)}`);

    this.log(`getPatientDetails()`);

    return data;
  }

  async getPatientMultipleSearch(input: string, size: number = DEFAULT_PAGINATION_SIZE, page: number = 1) {
    const { data } = await axiosMYLAB.get<Paginate<Patient>>(`${this.apiConfig.PATIENTS.SEARCH(this.getLang())}`, {
      params: {
        size,
        page,
        multiple_search: input,
      },
    });

    this.log('getPatientMultipleSearch()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new PatientsService(apiConfig);
};
