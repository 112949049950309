import RoutePaths from 'utils/RoutePaths';

export type UserRole = 'doctor' | 'patient' | 'clerk' | 'temp_patient' | 'laboratory' | 'bionext_admin';

export type RelationUsualInfo = {
  id: number;
  first_name: string;
  last_name: string;
  birth_date: string | null;
  invitationId: number;
};

export type ShortenPerson = {
  id: number;
  is_relative: boolean;
  first_name: string;
  last_name: string;
  marital_name: string;
  gender: Gender;
  addresses: {
    home?: Address;
    work?: Address;
  };
  birth_name: string;
  birth_date: string;
  cns: string;
  phone: string;
  mobile: string;
  fax?: string;
  email: string;
  username: string;
  patient_creation_enabled?: boolean;
};

export type Person = {
  mobile: string;
  id: number;
  patient_id: number;
  cns: string;
  username: string;
  email: string;
  gender: Gender;
  first_name: string;
  last_name: string;
  marital_name: string;
  birth_name: string;
  birth_date: string;
  phone: string;
  mobile_phone?: string;
  fax?: string;
  nationality: null;
  close_persons: [];
  invitations: number;
  role?: UserRole;
  photo?: null;
  receive_notifications: boolean;
  sms_auth_enabled: null;
  last_accepted_disclaimer: number;
  dossier_reference: null;
  is_relative: boolean;
  is_token: boolean;
  is_disclaimer_needed: boolean;
  augmented_reports_enabled: boolean;
  email_verified: boolean;
  relatives: {
    grantees: Relation[];
    grantors: Relation[];
    guests: Relation[];
  };
  addresses: {
    home?: Address;
    work?: Address;
  };
  appointments?: { date: string }[];
  close_person?: Person[];
};

export type RelationInfo = {
  username: string | null;
  id: number;
  cns: string;
  first_name: string;
  last_name: string;
  marital_name: string;
  birth_name: string;
  birth_date: string | null;
  gender: Gender;
  title: string;
  addresses: {
    home: Address | null;
    work: Address | null;
  };
  mobile_phone: string | null;
  mobile_phone_info: string | null;
  phone: string | null;
  phone_info: string | null;
  fax: string | null;
  fax_info: string | null;
  email: string | null;
  email_info: string | null;
  dossiers: [];
  nationality: null;
  lis_id: null;
  communication_language: string | null;
  health_fund_id: string;
  insurance1_id: number | null;
  insurance1_number: string | null;
  insurance2_id: number | null;
  insurance2_number: string | null;
  mobile_phone_is_relative: boolean;
  family_doctor: {
    id: number;
    sil_id: string;
    phone: string;
    fax: string;
    email: string;
    address: Address | null;
    office_name: string;
    speciality: string;
    medical_staff: {
      id: number;
      first_name: string;
      last_name: string;
      title: string;
      speciality: string;
      provider_code: string;
      prescription_type: number | null;
      ftp_type: number;
    };
    auto_free_prescription: boolean;
  } | null;
  mylab_refused: boolean;
  observation: string;
  is_active: boolean;
  last_update: string;
};

export type Relation = {
  id: number;
  grantor: RelationInfo;
  grantee: RelationInfo;
  relation: string | null;
  is_pending: boolean;
};

export enum Gender {
  MALE = 'm',
  FEMALE = 'f',
  MALE_FULL = 'male',
  FEMALE_FULL = 'female',
  MALE_V4 = '1',
  FEMALE_V4 = '2',
}

export type Address = {
  id?: number | null;
  company?: string | null;
  number?: string | null;
  complement?: string | null;
  street?: string | null;
  country?: string | null;
  city?: string | null;
  postal_code?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  country_iso?: string | null;
  zip_code?: string | null;
};

export type Profile = {
  title: string;
  person: ShortenPerson;
  actions?: { label: string; routePath: keyof typeof RoutePaths }[];
};

export type PersonUpdate = {
  email: string;
  gender: Gender;
  first_name: string;
  last_name: string;
  birth_date: string;
  nationality?: string;
  cns?: string;
  birth_name?: string;
  addresses?: { home?: Address; work?: Address };
  phone?: string;
  mobile_phone?: string;
  fax?: string;
  receive_notifications?: boolean;
  sms_auth_enabled?: boolean;
};

export type EmailUpdateProps = {
  email: string;
  emailConfirmation: string;
};

export type PasswordUpdateProps = {
  password: string;
  password_confirm: string;
};

export type BadgesCount = {
  id: number;
  dossiers: number;
  eudccs: number;
  invoices: number;
  appointments: number;
  cp: Record<number, boolean>;
};

export type BadgesCountPatient = BadgesCount & {
  persons: BadgesCount[];
};
