import api, { ApiError } from 'api';
import { NopeObject } from 'nope-validator/lib/cjs/NopeObject';
import { Validatable } from 'nope-validator/lib/cjs/types';
import { UseFormHandleSubmit } from 'react-hook-form';
import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { GlobalState } from 'store/reducers';

type FormValuesShape<T> = Record<keyof T, Validatable<any> | NopeObject>;

export type ReduxThunkAction<T extends Action = AnyAction> = ThunkAction<void, GlobalState, typeof api, T>;

export type ValuesTypeExtractor<T> = T extends UseFormHandleSubmit<infer ValuesType> ? ValuesType : never;

export const tryCatch = async <ERROR = ApiError, RESULT = any>(operation: () => Promise<RESULT>, errorHandler: (e: ERROR) => void, finallyHandler?: () => void) => {
  try {
    await operation();
  } catch (e) {
    errorHandler(e as ERROR);
  } finally {
    if (finallyHandler) {
      finallyHandler();
    }
  }
};

export const arrayEquals = (a: any[], b: any[]) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const buildShape = <T>(values: FormValuesShape<T>) => {
  return values;
};

export const removeEmptyString = <T>(object: T) => {
  const destructObject = object as { [key: string]: any };
  Object.keys(destructObject).forEach((key) => {
    destructObject[key] = !destructObject[key] && typeof destructObject[key] === 'string' ? undefined : destructObject[key];
  });
  return destructObject as T;
};

export const colorGenerator = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const allPropsAreTruthy = (obj: Record<string, any>) => Object.values(obj).every((value) => Boolean(value));
