import { Box, Button, Grid, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import AddIcon from '@mui/icons-material/Add';
import FindPatientsForm from 'components/find-patients-form';
import PatientList from 'components/patient-list';
import { useContextRedirection, useCurrentUser, useNotification, useQuery } from 'hooks';
import { Patient, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CNS_CODE, DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import RoutePaths from 'utils/RoutePaths';
import { formatGender } from 'utils/func/Person.func';
import { allPropsAreTruthy } from 'utils';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';

const Patients: FC = () => {
  const { t } = useTranslation();
  const queryParams = useQuery();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const currentUser = useCurrentUser();

  const [isDataFromMPI, setIsDataFromMPI] = useState<boolean>(false);
  const [showCreatePatientButton, setShowCreatePatientButton] = useState<boolean>(false);

  const formData: PatientsListRequest = {
    first_name: undefined,
    last_name: undefined,
    cns: undefined,
    gender: undefined,
    birth_name: undefined,
    birth_date: undefined,
  };

  const [form, setForm] = useState(formData);
  const handleFormData = (data: PatientsListRequest) => {
    setForm(data);
    const resetedPaginator = { ...paginator, page: 1 };
    setPaginator(resetedPaginator);
    requestPatientList(resetedPaginator, data);
  };

  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };

  const [paginator, setPaginator] = useState(paginatorData);
  const patientListData: Patient[] | undefined = undefined;
  const [patientList, setPatientList] = useState<Patient[] | undefined>(patientListData);

  const handlePaginatorData = (data: GenericPaginator) => {
    setPaginator(data);
    requestPatientList(data, form);
  };
  const requestPatientListMPI = useCallback(
    (data: PatientsListRequest) => {
      api.patients
        .getPatientsMPI(data)
        .then((response: any) => {
          setIsDataFromMPI(true);
          setPatientList(response);
          setPaginator({ ...paginator, total: response.length });
          if (response.length === 0) {
            setShowCreatePatientButton(true);
          }
        })
        .catch((error) => {
          if (error?.code === 400) {
            notification(t('landing_page.login.token.notification.error.incorrectToken'), 'error');
            return;
          }
          setShowCreatePatientButton(true);
          setPatientList([]);
          setPaginator(paginatorData);
        });
    },
    [notification, paginator, paginatorData, t],
  );

  const requestPatientList = useCallback(
    (paginator: GenericPaginator, form: PatientsListRequest) => {
      api.patients
        .getPatients(form, paginator.size, paginator.page)
        .then((response: PatientsListResponse) => {
          if (response.total === 0) {
            requestPatientListMPI(form);
          } else {
            setIsDataFromMPI(false);
            setPatientList(response.results);
            setPaginator({
              total: response.total,
              size: response.size,
              page: response.page,
            });
          }
        })
        .catch(() => {
          setPatientList([]);
          notification(t('prescriptions.notification.error'), 'error');
        });
    },
    [currentUser?.patient_creation_enabled, notification, requestPatientListMPI, t],
  );

  // check before createPatient to format MPI DATA
  const formatBeforeCreatePatient = useCallback((patient: Patient) => {
    return {
      first_name: patient.first_name ?? '',
      last_name: patient.last_name ?? '',
      birth_name: patient.last_name ?? '',
      birth_date: patient.birth_date ? new Date(patient.birth_date).toISOString() : '',
      gender: formatGender(patient.gender),
      cns: patient.cns ?? '',
      health_fund_id: CNS_CODE,
      created_from_eprescription: true,
      addresses: patient.addresses ?? {},
    };
  }, []);

  const requestCreatePatient = useCallback(
    (patient: any) => {
      const formatedPatient = formatBeforeCreatePatient(patient);
      if (allPropsAreTruthy(formatedPatient)) {
        api.patients
          .createPatient({ ...formatedPatient, home: patient.addresses?.home })
          .then((response) => {
            PrescriptionRequestMaker(response.id, navigate);
          })
          .catch((e) => {
            const errorMessages = ['There is more than one patient with that social security number.', 'There is more than one patient with those data.'];
            if (errorMessages.includes(e.response?.data?.message)) {
              notification(`${t('patients.create.errors.already_exist')}`, 'error');
            }
          });
      }
    },
    [formatBeforeCreatePatient, navigate, notification, t],
  );

  useEffect(() => {
    queryParams.get('search') !== '' &&
      api.patients
        .getPatientMultipleSearch(queryParams.toString())
        .then((response) => {
          setPatientList(response.results);
          setPaginator({
            total: response.total,
            size: response.size,
            page: response.page,
          });
        })
        .catch(() => notification(t('patients.notification.error'), 'error'));
  }, [notification, queryParams, t]);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.patients')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12} sm={4}>
          <FindPatientsForm handleFormAction={handleFormData} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <PatientList
            patientDetailAction={{ to: 'DOCTOR_PATIENTS_DETAILS' }}
            patientList={patientList}
            paginatorData={paginator}
            handlePaginatorAction={handlePaginatorData}
            requestCreatePatient={requestCreatePatient}
            isDataFromMPI={isDataFromMPI}
          />

          {showCreatePatientButton && (
            <Box display='flex' alignItems='center' justifyContent='flex-end' mt={3}>
              <Typography variant='h2' fontSize='14px' mr={2}>
                {t('patients.create.description')}
              </Typography>
              <Button variant='contained' startIcon={<AddIcon />} onClick={() => navigate(RoutePaths['DOCTOR_CREATE_PATIENTS'])}>
                {t('patients.create.button_title')}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Patients;
