import config from 'config';
import { buildingUrl } from './func/eSante.func';

const eudcc = `eudcc`;
const EudccCertificatePaths = {
  EUDCC_CERTIFICATES: `/${eudcc}`,
  EUDCC_CERTIFICATE_DETAILS: ({ id, fileName }: { id: string; fileName: string }) => `/${eudcc}/${id}/${fileName}`,
};

const profile = `profile`;
const ProfilePaths = {
  PROFILE: `/${profile}`,
  PROFILE_UPDATE: `/${profile}/update`,
  PROFILE_RELATIVE: `/${profile}/relative`,
  PROFILE_ADD_RELATIVE: `/${profile}/relative/add`,
  PROFILE_ADD_RELATIVE_FORM: `/${profile}/relative/add/form`,
};

const invoice = `invoices`;
const InvoicePaths = {
  INVOICES: `/${invoice}`,
  INVOICES_OPEN: `/${invoice}/open`,
  INVOICES_PAID: `/${invoice}/paid`,
  INVOICE_DETAILS: ({ id }: { id: number }) => `/${invoice}/${id}`,
};

const result = `results`;
const ResultPaths = {
  RESULTS: `/${result}`,
  RESULTS_RELATIVE: `/${result}/relative`,
  DETAIL_RESULT: ({ id }: { id?: number | string }) => `/${result}/${id}`,
  DETAIL_RESULT_FORM: ({ dossierId, patientId }: { dossierId: string; patientId?: number }) => `/${result}/${dossierId}/form/${patientId}`,
  DETAIL_PDF_PRESCRIPTION: ({ dossierId, filename }: { dossierId: string; filename: string }) => `/${result}/${dossierId}/pdf/prescription/${filename}`,
  DETAIL_PDF_EUDCC: ({ dossierId, filename }: { dossierId: string; filename: string }) => `/${result}/${dossierId}/pdf/eudcc/${filename}`,
  DETAIL_RESULT_PDF: ({ dossierId, type, filename }: { dossierId: string; type: string; filename?: string }) => `/${result}/${dossierId}/pdf/${type}/${filename}`,
  DETAIL_RESULT_QUESTIONNAIRE: ({ folder, questionnaire }: { folder: number; questionnaire: string }) => `/${result}/${folder}/questionnaires/${questionnaire}`,
  DETAIL_RESULT_PATIENT_QUESTIONNAIRE: ({ folder, questionnaire }: { folder: number; questionnaire: string }) => `/${result}/${folder}/patient/questionnaires/${questionnaire}`,
};

const myDSPPaths = {
  MY_DSP: `/myDSP`,
  MY_DSP_DETAIL: ({ uuid }: { uuid: number | string }) => `/myDSP/${uuid}`,
};

const DoctorPaths = {
  DOCTOR_PATIENTS: `/patients`,
  DOCTOR_CREATE_PATIENTS: `/patients/create`,
  DOCTOR_PATIENTS_HISTORY_REFERENCE: ({ id, reference }: { id: string; reference: string }) => `/patients/${id}/crosstab?reference=${reference}`,
  DOCTOR_PATIENTS_DETAILS: (id?: string) => `/patients/${id}`,
  DOCTOR_PATIENTS_CROSSTAB: ({ id }: { id: string }) => `/patients/${id}/crosstab`,
  DOCTOR_PATIENTS_DETAILS_PRESCRIPTION: ({ id }: { id: number }) => `/prescription/${id}`,
  DOCTOR_RESULTS: `/results`,
  DOCTOR_PRESCRIPTION: `/prescriptions`,
  DOCTOR_PRESCRIPTION_DETAILS: ({ id }: { id: number }) => `/prescription/${id}`,
  DOCTOR_PRESCRIPTION_DETAILS_COMPLETION: ({ id }: { id: undefined | string }) => `/prescription/${id}/completion`,
  DOCTOR_PRESCRIPTION_DETAILS_VALIDATION: ({ id }: { id: undefined | string }) => `/prescription/${id}/validation`,
  DOCTOR_PRESCRIPTION_PROFILES_NEW: `/prescriptions/profile`,
  DOCTOR_PRESCRIPTION_PROFILES_UPDATE: (id?: number) => `/prescriptions/profile/${id}`,
  DOCTOR_APPOINTMENT: ({ id }: { id: number }) => `/appointment/patient/${id}`,
  DOCTOR_APPOINTMENT_PRESCRIPTION: ({ id, prescId }: { id: number; prescId: number }) => `/appointment/patient/${id}/${prescId}`,
  DOCTOR_SELECT_OMS: ({ id }: { id: number }) => `/prescriptions/oms/${id}`,
};

export const RoutePaths = {
  // Default routes
  LANDING_PAGE: `/`,
  NOT_FOUND: '/404',
  LOGIN: `/login`,
  LOGOUT: `/logout`,
  REGISTER: `/register`,
  FAQ: `/faq`,
  GDPR: `/gdpr`,
  DISCLAIMER: '/disclaimer',
  CONTACT_US: `/contact`,
  ANALYSIS_CATALOG: `/analysis`,
  PRIO_PHONE: ({ phoneNumber }: { phoneNumber: string }) => `tel: ${phoneNumber}`,
  APPOINTMENT: `/appointment`,
  BOOK_APPOINTMENT: `/appointment/book`,
  RESET_TOKEN: ({ token }: { token: string }) => `/resetting/reset/${token}`,
  DOCTENA_APPOINTMENT: ({ public_uri, locale, doctorTrad }: { public_uri: string; locale: string; doctorTrad: string }) => `https://www.doctena.lu/${locale}/${doctorTrad}/${public_uri}`,
  MADE_IN_LUXEMBOURG: 'https://www.bionext.lu/a-propos',
  HOSTED_IN_LUXEMBOURG: 'https://www.bionext.lu/nos-engagements#HostedInLuxembourg',
  LABOMOBILE: 'https://www.bionext.lu/espace-patients/service/labomobile',
  BNL_ENTERPRISES: 'https://www.bionext.lu/espace-entreprises/services/medicaux',
  GOOGLE_PLAY_STORE: config.myLabAppAndroid,
  APP_STORE: config.myLabAppiOS,
  // Patient routes
  HOME: `/home`,
  BIONEXT_TV: `/tv`,
  CENTERS: `/centers`,
  ESANTE_URL_BUILDING: ({ codeChallenge }: { codeChallenge: string }) => buildingUrl(),
  BIONEXT_PATH: config.websiteBionextApiUrl,
  FIND_DOCTOR: `/find-doctor`,
  PREREGISTRATION: '/preregistration',
  SAFEPLACE: ({ locale }: { locale: string }) => `https://safeplace.bionext.lu/${locale}`,
  ACTIVATE_DSP: `https://www.esante.lu/portal/fr/je-suis-un-patient/j-active-mon-dsp-197-215.html`,
  // Doctor routes
  ...DoctorPaths,
  // Decomposition routes
  ...EudccCertificatePaths,
  ...InvoicePaths,
  ...ResultPaths,
  ...ProfilePaths,
  ...myDSPPaths,
};

export default RoutePaths;
