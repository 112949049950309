import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { Gender } from 'models/profile.model';
import { FC, useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { convertDate } from 'utils/func/Date.func';
import { useContextRedirection, useQuery } from 'hooks';
import RoutePaths from 'utils/RoutePaths';

interface FindPatientsFormProps {
  halfWidth?: boolean;
  handleFormAction: (data: CreatePatientForm) => void;
}

export interface CreatePatientForm {
  first_name: string;
  last_name: string;
  gender: Gender;
  cns: string;
  birth_name: string;
  birth_date: string | Date;
}
const CreatePatientsForm: FC<FindPatientsFormProps> = ({ halfWidth = false, handleFormAction }) => {
  const navigate = useContextRedirection();
  const { t } = useTranslation();
  const query = useQuery();
  const theme = useTheme();

  const { register, handleSubmit, control, watch, setValue } = useForm<CreatePatientForm>({ defaultValues: { cns: query.get('cns') ?? '' } });

  const onSubmit: SubmitHandler<CreatePatientForm> = useCallback(
    (data) => {
      const { birth_date } = data;
      const birth_dateShort = birth_date ? convertDate(birth_date, false, 'yyyy-MM-dd') : '';
      handleFormAction({
        ...data,
        birth_date: birth_dateShort,
      });
    },
    [handleFormAction],
  );

  const { first_name, birth_name, last_name } = watch();
  const sexes = [
    {
      label: 'common.sex.woman',
      value: Gender.FEMALE_V4,
    },
    {
      label: 'common.sex.man',
      value: Gender.MALE_V4,
    },
  ];

  const clearField = (name: keyof CreatePatientForm) => setValue(name, '', { shouldValidate: true, shouldTouch: true });
  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title={
          <Typography variant='h2' mb={4}>
            {t('patients.create.title')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', flex: '1 0 0' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item spacing={{ xs: 2, md: 2 }}>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField
                  variant='filled'
                  {...register('birth_name')}
                  label={t('patients.search.fields.birth_name')}
                  fullWidth
                  InputProps={{
                    endAdornment: birth_name?.length > 0 && (
                      <IconButton onClick={() => clearField('birth_name')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField
                  variant='filled'
                  {...register('last_name')}
                  defaultValue={undefined}
                  label={t('patients.search.fields.patient_name')}
                  fullWidth
                  InputProps={{
                    endAdornment: last_name?.length > 0 && (
                      <IconButton onClick={() => clearField('last_name')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField
                  variant='filled'
                  {...register('first_name')}
                  label={t('patients.search.fields.patient_first_name')}
                  fullWidth
                  InputProps={{
                    endAdornment: first_name?.length > 0 && (
                      <IconButton onClick={() => clearField('first_name')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <Controller
                  name='birth_date'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label={t('patients.search.fields.birth_date')}
                      openTo='year'
                      views={['year', 'month', 'day']}
                      {...field}
                      slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField select variant='filled' label={t('patients.search.fields.sex')} {...register('gender')} defaultValue={''} fullWidth>
                  {sexes.map(({ label, value }, i) => {
                    return (
                      <MenuItem value={value} key={`sex-${i}`}>
                        {t(label)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: (theme) => theme.spacing(6),
            }}
          >
            <Button type='submit' variant='contained'>
              {t('patients.create.button_title')}
            </Button>
            <Button sx={{ ml: 3 }} startIcon={<ArrowBackIcon />} onClick={() => navigate(RoutePaths.DOCTOR_PATIENTS)}>
              {t('patients.create.back_title')}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreatePatientsForm;
