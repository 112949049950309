import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers';
//import ContextLink from 'components/ContextLink';
import { useTranslation } from 'react-i18next';
import { PatientsListRequest, PatientsListRequestForm } from 'models/patients.model';
import { Gender } from 'models/profile.model';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { convertDate } from 'utils/func/Date.func';
import { useQuery } from 'hooks';
//import { generateCodeChallenge, generateCodeVerifier } from 'utils/func/eSante.func';

interface FindPatientsFormProps {
  halfWidth?: boolean;
  handleFormAction: (data: PatientsListRequest) => void;
}

const CNS_MIN_LENGTH = 8;

const FindPatientsForm: FC<FindPatientsFormProps> = ({ halfWidth = false, handleFormAction }) => {
  const { t } = useTranslation();
  const query = useQuery();
  const theme = useTheme();

  const { register, handleSubmit, control, formState, setValue, reset, watch } = useForm<PatientsListRequestForm>({ defaultValues: { cns: query.get('cns') ?? '' } });

  const { cns, first_name, last_name } = watch();
  const onSubmit: SubmitHandler<PatientsListRequestForm> = useCallback(
    (data) => {
      const { birth_date } = data;
      const birth_dateShort = birth_date ? convertDate(birth_date, false, 'yyyy-MM-dd') : '';
    
      handleFormAction({
        ...data,
        birth_date: birth_dateShort,
      });
    },
    [handleFormAction],
  );

  const isInvalidCNS = formState.isSubmitted && formState.dirtyFields['cns'] && (cns?.length || 0) < CNS_MIN_LENGTH;
  const clearField = (name: keyof PatientsListRequestForm) => setValue(name, '', { shouldValidate: true, shouldTouch: true });

  return (
    <Card
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title={
          <Typography variant='h2' mb={4}>
            {t('patients.search.title')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', flex: '1 0 0' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item>
              <Grid item xs={12}>
                <Typography variant='body1' mb={1.5} fontWeight='500'>
                  {t('patients.search.with_cns')}
                </Typography>
                <TextField
                  variant='filled'
                  {...register('cns', { minLength: CNS_MIN_LENGTH })}
                  label={t('patients.search.fields.cns')}
                  error={isInvalidCNS}
                  sx={{ width: '100%' }}
                  helperText={isInvalidCNS && t('patients.search.errors.cns_length', { count: CNS_MIN_LENGTH })}
                  InputProps={{
                    endAdornment: (cns?.length || 0) > 0 && (
                      <IconButton onClick={() => clearField('cns')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: '3%',
                    marginBottom: '3%',
                    '&::before, &::after': {
                      borderColor: 'black',
                    },
                  }}
                >
                  <Typography variant='h3'>{t('landing_page.login.or')}</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' mb={1.5} fontWeight='500'>
                  {t('patients.search.without_cns')}
                </Typography>
                <TextField
                  variant='filled'
                  {...register('last_name')}
                  label={`${t('patients.search.fields.patient_name')} *`}
                  fullWidth
                  InputProps={{
                    endAdornment: (last_name?.length || 0) > 0 && (
                      <IconButton onClick={() => clearField('last_name')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='filled'
                  {...register('first_name')}
                  label={`${t('patients.search.fields.patient_first_name')} *`}
                  fullWidth
                  InputProps={{
                    endAdornment: (first_name?.length || 0) > 0 && (
                      <IconButton onClick={() => clearField('first_name')}>
                        <ClearIcon sx={{ color: `${theme.palette.error.main} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='birth_date'
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <DatePicker
                      label={`${t('patients.search.fields.birth_date')} *`}
                      openTo='year'
                      views={['year', 'month', 'day']}
                      {...field}
                      slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                    />
                  )}
                />
                <Typography fontSize='14px' mt={1}>
                  {t('patients.search.required_fields')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: (theme) => theme.spacing(6),
            }}
          >
            <Button type='submit' variant='contained'>
              {t('patients.search.button')}
            </Button>
            {formState.isDirty && (
              <Button sx={{ ml: 2 }} onClick={() => reset()}>
                {t('patients.search.reset')}
              </Button>
            )}
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default FindPatientsForm;
