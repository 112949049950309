import { Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import CreatePatientsForm, { CreatePatientForm } from 'components/create-patients-form';
import { useContextRedirection, useNotification } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CNS_CODE } from 'utils/Constants';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';
import { formatGender } from 'utils/func/Person.func';

const CreatePatients: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();

  const handleFormData = (data: CreatePatientForm) => {
    const formatBeforeCreatePatient = (patient: CreatePatientForm) => {
      return {
        first_name: patient.first_name ?? '',
        last_name: patient.last_name ?? '',
        birth_name: patient.last_name ?? '',
        birth_date: patient.birth_date ? new Date(patient.birth_date).toISOString() : '',
        gender: formatGender(data.gender),
        cns: patient?.cns ?? '',
        health_fund_id: CNS_CODE,
        created_from_eprescription: true,
      };
    };
    api.patients
      .createPatient(formatBeforeCreatePatient(data))
      .then((response) => {
        PrescriptionRequestMaker(response.id, navigate);
        notification(t('patients.create.success'), 'success');
      })
      .catch((e) => {
        const errorMessages = ['There is more than one patient with that social security number.', 'There is more than one patient with those data.'];
        if (errorMessages.includes(e.response?.data?.message)) {
          notification(`${t('patients.create.errors.already_exist')}`, 'error');
        }
      });
  };

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.patients')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <CreatePatientsForm handleFormAction={handleFormData} />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default CreatePatients;
